import { search as searchHandle} from './api';
document.addEventListener('DOMContentLoaded', (event) => {

    const search = document.getElementById('search');
    const searchInput = search.children[1];
    let searchValue = '';
    const searchList = document.querySelector('.search__list');

    search.addEventListener("keyup", () => {
        searchValue = searchInput.value;

        if (!searchValue || searchValue.length <= 2) {
            return false;
        }

        searchHandle(searchValue)
            .then(response => {
                searchList.classList.remove('display-none');
                searchList.innerHTML = response;
            })
    });

    searchInput.addEventListener('click', (event) => {
        search.classList.add('search__wrapper--active');

        searchValue = searchInput.value;

        if (!searchValue || searchValue.length <= 2) {
            return false;
        }

        if (searchValue.length) {
            searchHandle(searchValue)
                .then(response => {
                    searchList.classList.remove('display-none');
                    searchList.innerHTML = response;
                })
        }
    });


    window.addEventListener('click', function (e) {
        if (!search.contains(e.target)) {
            search.classList.remove('search__wrapper--active');
            searchList.classList.add('display-none');
        }
    });
});
