import '../scss/app.scss'
import '../../node_modules/glider-js/glider.min.css'
import Glider from 'glider-js';
import './search'

window.addEventListener('load', () => {
    // Is service worker available?
    if ('serviceWorker' in navigator) {
        navigator.serviceWorker.register('/sw.js').then(() => {
            console.log('Service worker registered!');
        }).catch((error) => {
            console.warn('Error registering service worker:');
            console.warn(error);
        });
    }
});

function addIframe(div, videoId, videoTitle) {
    let iframe = document.createElement("iframe");
    iframe.setAttribute(
        "src",
        "https://www.youtube-nocookie.com/embed/" + div.dataset.id + "?autoplay=1&rel=0"
    );
    iframe.setAttribute("allowfullscreen", true);
    iframe.setAttribute("title", videoTitle);
    iframe.setAttribute("width", 560);
    iframe.setAttribute("height", 315);
    iframe.setAttribute(
        "allow",
        "accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
    );

    div.classList.add('embed-activated');
    div.appendChild(iframe);
}
function addPrefetch(kind, url, as) {
    const linkEl = document.createElement('link');
    linkEl.rel = kind;
    linkEl.href = url;
    if (as) {
        linkEl.as = as;
    }
    document.head.append(linkEl);
}
function initYouTubeVideos() {
    let playerElements = document.getElementsByClassName("youtube-player");

    for (let n = 0; n < playerElements.length; n++) {
        let videoId = playerElements[n].dataset.id;
        let videoTitle = playerElements[n].dataset.title;
        let posterUrl = `https://i.ytimg.com/vi/${videoId}/hqdefault.jpg`;

        playerElements[n].setAttribute("style", `background-image: url(${posterUrl})`);
        playerElements[n].classList.add('lozad');
        playerElements[n].setAttribute("data-background-image", posterUrl);

        addPrefetch('preload', posterUrl, 'image');

        let playButton = document.createElement("button");
        playButton.setAttribute("class", "playbtn");
        playButton.setAttribute("type", "button");
        playButton.innerHTML = `<span class="embed-visually-hidden">Play Video: ${videoTitle}</span>`;

        playerElements[n].appendChild(playButton);

        playButton.addEventListener("click",  function () {
            addIframe(playerElements[n], videoId, videoTitle);
        });
    }
}

document.addEventListener('DOMContentLoaded', (event) => {
    const sliders = document.querySelectorAll('.js-slider');

    initYouTubeVideos();

    sliders && sliders.forEach(slider => {
        new Glider(slider, {
            slidesToShow: 10,
            slidesToScroll: 5,
            draggable: true,
            scrollPropagate: true,
            itemWidth: 150,
            arrows: {
                prev: '.glider-prev',
                next: '.glider-next'
            }
        });
    });
});

