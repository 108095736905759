export function search(q) {
    return new Promise((resolve, reject) => {
        const target = new URL(window.api.search.url);
        const params = new URLSearchParams();
        params.set('q', q);
        target.search = params.toString();

        let xhr = new XMLHttpRequest();
        xhr.open('GET', target.href, true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
        xhr.send();

        xhr.onload = function () {
            if (xhr.status === 200) {
                resolve(xhr.response);
            } else {
                reject();
            }
        }
    });
}
export function postComment(message, entityId, entityType) {
        return new Promise((resolve, reject) => {
            const data = JSON.stringify({
                entityId: parseInt(entityId),
                entityType: entityType,
                message: message,
            });

            let xhr = new XMLHttpRequest();
            xhr.open('POST', window.api.comments.post, true)
            xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
            xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
            xhr.send(data);

            xhr.onload = function () {
                if (xhr.status === 200) {
                    resolve(xhr.response);
                } else {
                    reject(xhr);
                }
            }
        });
    }
export function downloadZip(entityId, entityType) {
        return new Promise((resolve, reject) => {
            const formData = new FormData();

            formData.append('entityId', entityId);
            formData.append('entityType', entityType);

            let xhr = new XMLHttpRequest();
            xhr.open('POST', window.api.downloadZip, true)
            // xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
            xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
            xhr.responseType = 'blob';
            xhr.send(formData);


            xhr.onload = function (e) {
                if (xhr.status === 200) {
                    let contentDisposition = e.currentTarget.getResponseHeader('Content-Disposition');

                    if (! contentDisposition) {
                        return;
                    }
                    let blob = e.currentTarget.response;
                    let fileName = contentDisposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1];

                    resolve({blob, fileName});
                } else {
                    reject(xhr);
                }
            }
        });
    }
export function userBookmarks() {
    return new Promise((resolve, reject) => {

        let xhr = new XMLHttpRequest();
        xhr.open('GET', '/api/user/bookmarks', true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
        xhr.responseType = 'json';
        xhr.send();


        xhr.onload = function (e) {
            if (xhr.status === 200) {
                resolve(xhr.response);
            } else {
                reject(xhr);
            }
        }
    });
}
export function addUserBookmark(titleId, bookmarkId) {
    return new Promise((resolve, reject) => {
        const formData = new FormData();

        formData.append('titleId', titleId);
        formData.append('bookmarkId', bookmarkId);

        let xhr = new XMLHttpRequest();
        xhr.open('POST', '/api/user/bookmarks', true)
        // xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8')
        xhr.setRequestHeader('X-CSRF-TOKEN', document.querySelector('meta[name="csrf-token"]').content);
        xhr.responseType = 'json';
        xhr.send(formData);

        xhr.onload = function (e) {
            if (xhr.status === 200 || xhr.status === 204) {
                resolve(xhr.response || null);
            } else {
                reject(xhr || null);
            }
        }
    });
}

export function bookmarkById(bookmarkId) {
    return new Promise((resolve, reject) => {

        let xhr = new XMLHttpRequest();
        xhr.open('GET', `/api/user/bookmarks/${bookmarkId}`, true)
        xhr.setRequestHeader('Content-type', 'application/json; charset=UTF-8');
        xhr.responseType = 'json';
        xhr.send();


        xhr.onload = function (e) {
            if (xhr.status === 200) {
                resolve(xhr.response);
            } else {
                reject(xhr);
            }
        }
    });
}

